import React, { useEffect, useState } from 'react';

import { TbCheck } from 'react-icons/tb';
import { HiArrowUpRight } from 'react-icons/hi2';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container } from './styles';

import Carousel from '~/components/Carousel';

import api from '~/services/api';

interface IItem {
  id: string;
  item_url: string;
}

interface IFile {
  id: string;
  type: string;
  file_url: string;
}

interface IProjectResponse {
  title: string;
  description: string;
  link: string;
  tags: string;
  files: IFile[];
}

interface IProject {
  title: string;
  description: string;
  link: string;
  banner_url: string;
  items: IItem[];
  tags: string[];
}

interface IParams {
  slug: string;
}

const Project: React.FC = () => {
  const params = useParams<IParams>();
  const [product, setProduct] = useState({} as IProject);

  useEffect(() => {
    api.get<IProjectResponse>(`projects/${params.slug}`).then((response) => {
      const tags = response.data.tags.split(', ');
      const items: IItem[] = response.data.files
        .filter((file) => file.type === 'carousel')
        .map((file) => ({
          id: file.id,
          item_url: file.file_url,
        }));
      const banner = response.data.files.find((file) => file.type === 'banner');
      setProduct({
        title: response.data.title,
        description: response.data.description,
        link: response.data.link,
        banner_url: banner ? banner.file_url : '',
        items,
        tags,
      });
    });
  }, [params.slug]);

  return (
    <>
      <Helmet>
        <meta
          property="og:url"
          content={`https://devsigner.com.br/projetos/${params.slug}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${product.title} - Devsigner Systems`}
        />
        <meta
          property="og:description"
          content={`O Projeto da ${product.title} que foi desenvolvido por nós a Devsigner Systems`}
        />
        <meta property="og:image" content={`${product.banner_url}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="devsigner.com.br" />
        <meta
          property="twitter:url"
          content={`https://devsigner.com.br/projetos/${params.slug}`}
        />
        <meta
          name="twitter:title"
          content={`${product.title} - Devsigner Systems`}
        />
        <meta
          name="twitter:description"
          content={`O Projeto da ${product.title} que foi desenvolvido por nós a Devsigner Systems`}
        />
        <meta name="twitter:image" content={`${product.banner_url}`} />
      </Helmet>
      <Container className="py-lg-5">
        {Object.keys(product).length > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-12 mb-4">
                <h1 className="h6 h5-lg fw-medium text-center">Projeto:</h1>
                <h2 className="mb-5 h3 h1-lg fw-normal text-primary text-center d-flex align-items-center justify-content-center">
                  {product.title}{' '}
                  <a
                    href={product.link}
                    className="bg-primary d-flex align-items-center justify-content-center rounded-circle ms-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <HiArrowUpRight size={16} color="#fff" />
                  </a>
                </h2>
                <img
                  src={product.banner_url}
                  alt={product.title}
                  className="w-100 mb-5"
                />
                <p className="mb-5 pb-5 h4-lg fw-normal text-secondary text-center">
                  {product.description}
                </p>
                <h3 className="mb-5 h3 h1-lg fw-normal text-primary">
                  Mais sobre o projeto
                </h3>
                <div>
                  <Carousel className="carousel">
                    {product.items.map((item) => (
                      <div key={item.id} className="px-3">
                        <img
                          src={item.item_url}
                          alt="item01"
                          className="w-100"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div className="d-flex flex-wrap justify-content-center tags mt-4 pt-5">
                  {product.tags.map((tag, index) => (
                    <span key={index.toString()} className="my-3">
                      <TbCheck size={33} color="#47E3C7" className="me-2" />{' '}
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="pt-5 pb-4">
                  <div className="py-5 my-5">
                    <h4 className="mb-4 h3-lg h1-lg fw-normal text-primary text-center">
                      Gostaria de fazer <br className="d-none d-lg-block" />
                      um orçamento?
                    </h4>
                    <p className="mb-5 h5-lg fw-normal text-secondary text-center">
                      Tenha projetos personalizados e{' '}
                      <br className="d-none d-lg-block" />
                      funcionais para seus usuários.
                    </p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/quero-um-projeto/sobre-seu-projeto/que-tipo-de-projeto-voce-deseja`}
                      className="btn btn-budget rounded-pill mx-auto d-block px-4 py-2 w-md-50 w-lg-25"
                    >
                      Quero um projeto
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default Project;
