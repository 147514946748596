import React from 'react';

import { IoLogoWhatsapp } from 'react-icons/io';
import { AiFillInstagram } from 'react-icons/ai';
import { RiLinkedinFill } from 'react-icons/ri';
import { Helmet } from 'react-helmet';

import contact from '~/assets/defaults/contact.svg';
import { Container } from './styles';
import blueLogo from '~/assets/logos/blue-logo.svg';

const Contact: React.FC = () => {
  return (
    <Container className="py-lg-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-1 order-lg-0 px-5 px-lg-3 mb-5 mb-lg-0">
            <h1 className="h3 h1-lg fw-normal text-primary mb-4 text-center text-lg-start">
              Alguma dúvida?
            </h1>
            <p className="mb-0 h3-lg fw-normal text-secondary text-center text-lg-start">
              Entre em contato conosco para transformar sua visão em realidade.
              Estamos prontos para criar experiências incríveis juntos.
            </p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center order-0 order-lg-1 mb-5 mb-lg-0">
            <img src={contact} alt="contact" className="w-75 w-lg-auto" />
          </div>
          <div className="col-lg-4 my-3 my-lg-5 order-1">
            <a
              href="https://api.whatsapp.com/send?phone=5511913096161&text=Ol%C3%A1!%20Estou%20com%20d%C3%BAvidas%20pode%20me%20ajudar?"
              className="d-flex align-items-center"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon d-flex align-items-center justify-content-center me-3">
                <IoLogoWhatsapp size={34} color="#fff" />
              </span>
              <span className="d-flex flex-column">
                <b className="fw-semibold text-primary mb-1">WhatsApp</b>
                <span className="text-secondary">+55 (11) 91309-6161</span>
              </span>
            </a>
          </div>
          <div className="col-lg-4 my-3 my-lg-5 order-1">
            <a
              href="https://www.instagram.com/_devsigner/"
              className="d-flex align-items-center"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon d-flex align-items-center justify-content-center me-3">
                <AiFillInstagram size={34} color="#fff" />
              </span>
              <span className="d-flex flex-column">
                <b className="fw-semibold text-primary mb-1">Instagram</b>
                <span className="text-secondary">@_Devsigner</span>
              </span>
            </a>
          </div>
          <div className="col-lg-4 mt-3 mb-5 my-lg-5 order-1">
            <a
              href="https://www.linkedin.com/company/devsigner-systems/about/"
              className="d-flex align-items-center"
              target="_blank"
              rel="noreferrer"
            >
              <span className="icon d-flex align-items-center justify-content-center me-3">
                <RiLinkedinFill size={34} color="#fff" />
              </span>
              <span className="d-flex flex-column">
                <b className="fw-semibold text-primary mb-1">LinkedIn</b>
                <span className="text-secondary">devsigner-systems</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
